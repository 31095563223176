// File: src/components/UploadVideo.jsx

import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { v4 as uuidv4 } from 'uuid';
import './UploadVideo.css';
import { useUploadProgress } from '../context/UploadProgressContext.js';

const API_URL = process.env.REACT_APP_API_URL;

const UploadVideo = () => {
    const [uploadType, setUploadType] = useState('single'); // 'single' or 'stitch'
    const [videoFile, setVideoFile] = useState(null);
    const [videoFiles, setVideoFiles] = useState([]); // dummy for stitch-upload
    const [token, setToken] = useState(null);

    const { 
      progress, 
      setProgress, 
      isUploading, 
      setIsUploading, 
      showNotification, 
      setProcessing, 
      processing,
      uploadMetadata,
      setUploadMetadata
    } = useUploadProgress();
    const [videoMetadata, setVideoMetadata] = useState({
        videoTitle: '',
        videoDescription: '',
        isPublic: false,
        patientGender: 'male',
        surgerySpeciality: '',
        surgeryType: '',
      });
    const navigate = useNavigate();
    const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();

    const [groups, setGroups] = useState([]); // To store user's groups
    const [error, setError] = useState(null);

    useEffect(() => {
      const getToken = async () => {
        try {
          const tok = await getAccessTokenSilently();
          setToken(tok);
        } catch (error) {
          console.error("Error fetching token:", error);
        }
      };
      getToken();
    }, [getAccessTokenSilently]);

    useEffect(() => {
      if (!token) return;
      const fetchUserGroups = async () => {
            try {
                const response = await axios.get(`${API_URL}/group/my-groups`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setGroups(response.data.groups);
            } catch (err) {
                console.error('Error fetching user groups:', err);
                setError(err.response?.data?.message || 'Error fetching user groups');
            }
        };

        fetchUserGroups();
    }, [token]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setVideoMetadata({ ...videoMetadata, [name]: value });
    };

  // For single upload: file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type !== 'video/mp4') {
      alert('Please upload a valid MP4 video file');
      setVideoFile(null);
      return;
    }
    setVideoFile(file);  

  };

  // For stitch upload (dummy for now): allow multiple files selection
  const handleMultiFileChange = (e) => {
    const files = Array.from(e.target.files);
    // Here you could validate each file if needed.
    setVideoFiles((prevFiles) => [...prevFiles, ...files]);
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (uploadType === 'single') {
      if (!videoFile) {
        alert('Please select a video file');
        return;
      }
      await handleSingleUpload();
    } else {
      // Stitch mode – require at least two files.
      if (videoFiles.length < 2) {
        alert('Please select at least two video files for stitching');
        return;
      }
      await handleStitchUpload();
    }
  };


  const handleSingleUpload = async () => {
    if (!token) return;

    try {
      setIsUploading(true);
      // Save metadata for later usage
      setUploadMetadata({
        videoTitle: videoMetadata.videoTitle,
        videoDescription: videoMetadata.videoDescription,
        isPublic: videoMetadata.isPublic,
        patientGender: videoMetadata.patientGender,
        surgerySpeciality: videoMetadata.surgerySpeciality,
        surgeryType: videoMetadata.surgeryType,
        fileName: videoFile.name,
      });
      navigate('/account');

      const videoId = uuidv4();

      // IMPORTANT: For single-upload, our key now is: uploads/single-<videoId>/<videoId>.mp4
      // Step 1: Initiate the multipart upload with the new key.
      const initiateResponse = await axios.post(
        `${API_URL}/storage/start-upload`,
        {
          videoId,
          fileType: videoFile.type,
          uploadType, // sending uploadType can be helpful if you need to differentiate on the backend
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const { uploadId } = initiateResponse.data;

      const totalSize = videoFile.size;
      let uploadedBytes = 0;
      const CHUNK_SIZE = 100 * 1024 * 1024; // 100MB
      const totalParts = Math.ceil(videoFile.size / CHUNK_SIZE);
      const parts = [];
      const uploadPromises = [];
      const maxConcurrency = 5;

      // Upload chunks
      for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
        const start = (partNumber - 1) * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, videoFile.size);
        const chunk = videoFile.slice(start, end);

        const uploadChunk = async (partNum, chunkData) => {
          try {
            const signedUrlResponse = await axios.post(
              `${API_URL}/storage/upload-chunk`,
              { videoId, partNumber: partNum, uploadId, fileType: videoFile.type, uploadType },
              { headers: { Authorization: `Bearer ${token}` } }
            );
            const { signedUrl } = signedUrlResponse.data;

            const response = await axios.put(signedUrl, chunkData, {
              headers: { 'Content-Type': videoFile.type },
              onUploadProgress: (event) => {
                uploadedBytes += event.loaded;
                const progressValue = Math.min(Math.round((uploadedBytes / totalSize) * 100), 100);
                setProgress(progressValue);
              },
            });

            parts[partNum - 1] = {
              ETag: response.headers.etag.replace(/"/g, ''),
              PartNumber: partNum,
            };
          } catch (error) {
            console.error(`Error uploading part ${partNum}:`, error);
            throw error;
          }
        };

        const uploadPromise = uploadChunk(partNumber, chunk).then(() => {
          uploadPromises.splice(uploadPromises.indexOf(uploadPromise), 1);
        });
        uploadPromises.push(uploadPromise);
        if (uploadPromises.length >= maxConcurrency) {
          await Promise.race(uploadPromises);
        }
      }

      await Promise.all(uploadPromises);
      setProgress(100);

      // Complete the multipart upload.
      await axios.post(
        `${API_URL}/storage/complete-upload`,
        { videoId, uploadId, parts, uploadType },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Save metadata about the video on your backend.
      await axios.post(
        `${API_URL}/video`,
        { videoId, ...videoMetadata, uploadType },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setIsUploading(false);
      setProcessing(true);
      setProgress(100);

      // Poll for processing status (as before)
      const pollStatus = async () => {
        try {
          const statusResponse = await axios.get(`${API_URL}/video/${videoId}/status`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          if (statusResponse.data.status === 'complete') {
            showNotification('Video processed and ready!');
            setProcessing(false);
            setUploadMetadata(null);
            setProgress(0);
          } else {
            setTimeout(pollStatus, 5000);
          }
        } catch (error) {
          console.error('Error checking processing status:', error);
          setTimeout(pollStatus, 5000);
        }
      };

      pollStatus();
    } catch (error) {
      console.error('Error uploading video:', error);
      setIsUploading(false);
      setProcessing(false);
    }
  };

  const handleStitchUpload = async () => {
    if (!token) return;

    try {
      // Generate one common videoId for the stitch job.
      const videoId = uuidv4();
      
      // Loop through each selected file.
      for (let index = 0; index < videoFiles.length; index++) {
        const file = videoFiles[index];
        // For clarity, set a partIndex (starting at 1)
        const partIndex = index + 1;
        
        // Initiate multipart upload for this file
        const initiateResponse = await axios.post(
          `${API_URL}/storage/start-upload`,
          { videoId, fileType: file.type, uploadType, partIndex },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const { uploadId } = initiateResponse.data;
        
        // The following code is similar to your single upload but now for this file.
        const totalSize = file.size;
        let uploadedBytes = 0;
        const CHUNK_SIZE = 100 * 1024 * 1024; // 100MB
        const totalParts = Math.ceil(file.size / CHUNK_SIZE);
        const parts = [];
        const uploadPromises = [];
        const maxConcurrency = 5;
        
        // Upload chunks for this file
        for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
          const start = (partNumber - 1) * CHUNK_SIZE;
          const end = Math.min(start + CHUNK_SIZE, file.size);
          const chunk = file.slice(start, end);
          
          const uploadChunkForStitch = async (pNumber, chunkData) => {
            try {
              const signedUrlResponse = await axios.post(
                `${API_URL}/storage/upload-chunk`,
                { videoId, partNumber: pNumber, uploadId, fileType: file.type, uploadType, partIndex },
                { headers: { Authorization: `Bearer ${token}` } }
              );
              const { signedUrl } = signedUrlResponse.data;
              const response = await axios.put(signedUrl, chunkData, {
                headers: { 'Content-Type': file.type },
                onUploadProgress: (event) => {
                  uploadedBytes += event.loaded;
                  const progressValue = Math.min(Math.round((uploadedBytes / totalSize) * 100), 100);
                  setProgress(progressValue);
                },
              });
              parts[pNumber - 1] = {
                ETag: response.headers.etag.replace(/"/g, ''),
                PartNumber: pNumber,
              };
            } catch (error) {
              console.error(`Error uploading part ${pNumber}:`, error);
              throw error;
            }
          };
          
          const uploadPromise = uploadChunkForStitch(partNumber, chunk).then(() => {
            uploadPromises.splice(uploadPromises.indexOf(uploadPromise), 1);
          });
          uploadPromises.push(uploadPromise);
          if (uploadPromises.length >= maxConcurrency) {
            await Promise.race(uploadPromises);
          }
        }
        
        await Promise.all(uploadPromises);
        setProgress(100);
        
        // Complete multipart upload for this file
        await axios.post(
          `${API_URL}/storage/complete-upload`,
          { videoId, uploadId, parts, uploadType, partIndex },
          { headers: { Authorization: `Bearer ${token}` } }
        );
      }
      
      // Optionally, after all files are uploaded you can:
      // - Trigger a stitch process (client- or server-side) to combine these files
      // - Save metadata about the stitch job
      // - Show a notification, etc.
      showNotification('All video parts have been uploaded. Stitch processing will begin soon.');
    } catch (error) {
      console.error('Error in stitch upload:', error);
    }
  };

    // Fetch user groups for group selection
    const [userGroups, setUserGroups] = useState([]);
    
    useEffect(() => {
      if (!token) return;

        const fetchUserGroups = async () => {
            try {
                const response = await axios.get(`${API_URL}/group/my-groups`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setUserGroups(response.data.groups);
            } catch (err) {
                console.error('Error fetching user groups:', err);
            }
        };

        fetchUserGroups();
    }, [token]);

    // Function to load combined options for users and groups
    const loadCombinedOptions = async (inputValue) => {
        if (!token) return;

        if (!inputValue) {
          return [];
        }
        try {
      
          // Fetch users
          const userResponse = await axios.get(`${API_URL}/user/search`, {
            headers: { Authorization: `Bearer ${token}` },
            params: { query: inputValue },
          });
      
          // Map user results to React-Select options
          const users = userResponse.data.users.map(user => ({
            value: user.userId,
            label: `User: ${user.name}`,
          }));
      
          // We no longer add "Groups" here, so only "Users"
          return users;
        } catch (error) {
          console.error('Error fetching user options:', error);
          return [];
        }
      };

      const loadGroupOptions = async (inputValue) => {
        if (!inputValue) {
          return [];
        }
        // Filter userGroups by input
        const filtered = userGroups.filter(group => 
          group.groupName.toLowerCase().includes(inputValue.toLowerCase())
        ).map(group => ({
          value: group.groupId,
          label: group.groupName,
        }));
      
        return filtered;
      };
      

    // Handle selection changes for operatedBy and allowedUsers
    const handleUserSelectionChange = (selectedOptions, field) => {
        if (!selectedOptions) {
          setVideoMetadata(prev => ({ ...prev, [field]: [] }));
          return;
        }
        const selectedUserIds = selectedOptions.map(option => option.value);
        setVideoMetadata(prev => ({ ...prev, [field]: selectedUserIds }));
      };
      
      // --- Snippet #4: New handler for group selections ---
      const handleGroupSelectionChange = (selectedOptions, field) => {
        if (!selectedOptions) {
          setVideoMetadata(prev => ({ ...prev, [field]: [] }));
          return;
        }
        const selectedGroupIds = selectedOptions.map(option => option.value);
        setVideoMetadata(prev => ({ ...prev, [field]: selectedGroupIds }));
      };


      const removeFile = (indexToRemove) => {
        setVideoFiles((prevFiles) =>
          prevFiles.filter((_, index) => index !== indexToRemove)
        );
      };
    
      // Discrete ordering functions using up/down buttons
      const moveFileUp = (index) => {
        if (index === 0) return;
        setVideoFiles((prevFiles) => {
          const newFiles = [...prevFiles];
          [newFiles[index - 1], newFiles[index]] = [newFiles[index], newFiles[index - 1]];
          return newFiles;
        });
      };
    
      const moveFileDown = (index) => {
        if (index === videoFiles.length - 1) return;
        setVideoFiles((prevFiles) => {
          const newFiles = [...prevFiles];
          [newFiles[index], newFiles[index + 1]] = [newFiles[index + 1], newFiles[index]];
          return newFiles;
        });
      };

    return (
        <div className="upload-editor-container">
            <h1 className="upload-title">Upload Video</h1>
            <h5 className="file-format-subtitle">Stay logged in until the video has been processed.</h5>
            <form className="upload-video-form" onSubmit={handleSubmit}>
        {/* Upload Type Selection */}
        <div className="form-group">
        {/* Upload Type Selection */}
        <div className="upload-type-container">
            <label className="upload-type-label">
                <input
                    type="radio"
                    name="uploadType"
                    value="single"
                    checked={uploadType === 'single'}
                    onChange={() => setUploadType('single')}
                />
                <span>Single Upload</span>
            </label>

            <label className="upload-type-label disabled-upload" title="Coming Soon!">
                <input
                    type="radio"
                    name="uploadType"
                    value="stitch"
                    checked={uploadType === 'stitch'}
                    onChange={() => setUploadType('stitch')}
                    disabled
                />
                <span>Stitch Upload</span>
            </label>

            
        </div>

        {/* Dynamic Description */}
        <p className="upload-type-description">
            {uploadType === 'single'
                ? "Upload a single video file directly to the platform."
                : "Combine multiple video files into one before uploading."}
        </p>
        </div>

        {/* Video Title */}
        <div className="form-group">
          <label htmlFor="videoTitle">Video Title<span className="required">*</span></label>
          <input
            type="text"
            id="videoTitle"
            name="videoTitle"
            placeholder="Enter Video Title"
            value={videoMetadata.videoTitle}
            onChange={handleInputChange}
            required
          />
        </div>

        {/* Video Description */}
        <div className="form-group">
          <label htmlFor="videoDescription">Video Description<span className="required">*</span></label>
          <textarea
            id="videoDescription"
            name="videoDescription"
            placeholder="Enter Video Description"
            value={videoMetadata.videoDescription}
            onChange={handleInputChange}
            required
          ></textarea>
        </div>

        {/* File Input */}
{/* File Input */}
<div className="form-group">
        {uploadType === 'single' ? (
          <>
            <label htmlFor="videoFile">Video File<span className="required">*</span></label>
            <input
              type="file"
              id="videoFile"
              accept="video/mp4"
              onChange={handleFileChange}
              required
            />
          </>
        ) : (
          <>
            <label htmlFor="videoFiles">
              Select Multiple Videos for Stitch Upload<span className="required">*</span>
            </label>
            <input
              type="file"
              id="videoFiles"
              accept="video/mp4"
              multiple
              onChange={handleMultiFileChange}
            />
            {videoFiles.length > 0 && (
              <div className="file-list-container">
                {videoFiles.map((file, index) => (
                  <div key={index} className="file-item">
                    <div className="order-controls">
                      <button
                        type="button"
                        className="up-button"
                        onClick={() => moveFileUp(index)}
                        disabled={index === 0}
                      >
                        ▲
                      </button>
                      <button
                        type="button"
                        className="down-button"
                        onClick={() => moveFileDown(index)}
                        disabled={index === videoFiles.length - 1}
                      >
                        ▼
                      </button>
                    </div>
                    <div className="file-order">{index + 1}.</div>
                    <div className="file-name">{file.name}</div>
                    <button
                      type="button"
                      className="delete-button"
                      onClick={() => removeFile(index)}
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
                {/* Public/Private */}
                <div className="form-group">
                    <label htmlFor="isPublic">Visibility<span className="required">*</span></label>
                    <select
                        id="isPublic"
                        name="isPublic"
                        value={videoMetadata.isPublic}
                        onChange={(e) => setVideoMetadata({ ...videoMetadata, isPublic: e.target.value === 'true' })}
                        required
                    >
                        <option value="true">Public</option>
                        <option value="false">Private</option>
                    </select>
                </div>

                {/* Surgery Speciality */}
                <div className="form-group">
                    <label htmlFor="surgerySpeciality">Speciality of Surgery<span className="required">*</span></label>
                    <input
                        type="text"
                        id="surgerySpeciality"
                        name="surgerySpeciality"
                        placeholder="Enter Speciality of Surgery"
                        value={videoMetadata.surgerySpeciality}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                {/* Surgery Type */}
                <div className="form-group">
                    <label htmlFor="surgeryType">Type of Surgery<span className="required">*</span></label>
                    <input
                        type="text"
                        id="surgeryType"
                        name="surgeryType"
                        placeholder="Enter Type of Surgery"
                        value={videoMetadata.surgeryType}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                {/* Operated By */}
                <div className="form-group">
                    <label htmlFor="operatedBy">Operated By (Users)</label>
                    <AsyncSelect
                        id="operatedBy"
                        isMulti
                        cacheOptions
                        defaultOptions
                        loadOptions={loadCombinedOptions} 
                        onChange={(selectedOptions) => handleUserSelectionChange(selectedOptions, 'operatedBy')}
                        placeholder="Select Users..."
                        classNamePrefix="react-select"
                    />
                </div>

                {/* Operated By Groups (Groups Only) */}
                <div className="form-group">
                    <label htmlFor="operatedByGroups">Operated By Groups</label>
                    <AsyncSelect
                        id="operatedByGroups"
                        isMulti
                        cacheOptions
                        defaultOptions
                        loadOptions={loadGroupOptions}
                        onChange={(selectedOptions) => handleGroupSelectionChange(selectedOptions, 'operatedByGroups')}
                        placeholder="Select Groups..."
                        classNamePrefix="react-select"
                    />
                </div>

                {/* Allowed Users */}
                <div className="form-group">
                    <label htmlFor="allowedUsers">Allowed Users</label>
                    <AsyncSelect
                        id="allowedUsers"
                        isMulti
                        cacheOptions
                        defaultOptions
                        loadOptions={loadCombinedOptions}
                        onChange={(selectedOptions) => handleUserSelectionChange(selectedOptions, 'allowedUsers')}
                        placeholder="Select Users..."
                        classNamePrefix="react-select"
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="allowedGroups">Allowed Groups</label>
                    <AsyncSelect
                        id="allowedGroups"
                        isMulti
                        cacheOptions
                        defaultOptions
                        loadOptions={loadGroupOptions}
                        onChange={(selectedOptions) => handleGroupSelectionChange(selectedOptions, 'allowedGroups')}
                        placeholder="Select Groups..."
                        classNamePrefix="react-select"
                    />
                </div>



                {/* Submit Button */}
                <div className="form-group">
                <button
                    type="submit"
                    className="upload-submit-button"
                    disabled={isUploading || processing} // disable if either is true
                    >
                    {isUploading || processing ? 'Video Uploading...' : 'Upload'}
                </button>
                </div>
            </form>



            {/* Error Message */}
            {error && <div className="error">{error}</div>}
        </div>
    );

};

export default UploadVideo;