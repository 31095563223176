import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate, useLocation } from 'react-router-dom';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';

import Home from './components/home/home.js';
import CasesVideo from './components/cases/cases_video.js';
import CasesHome from './components/cases/cases_home.js';
import Account from './components/auth/account.js';
import UploadVideo from './components/cases/UploadVideo.js';
import { UploadProgressProvider } from './components/context/UploadProgressContext.js'; // Import the provider
import CookieConsent from './components/common/CookieConsent';
import { Auth0Preloader } from './components/ui/Auth0Preloader.js';



import './App.css';

// Auth0 Credentials (replace with your actual Auth0 settings)
const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;


const App = () => {
    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: audience,
                scope: "openid profile email"
            }}
        >
            <Router>
                <UploadProgressProvider>
                    <AppContent />
                </UploadProgressProvider>
            </Router>
        </Auth0Provider>
    );
};

const AppContent = () => {
    const { isAuthenticated, loginWithRedirect, logout, user, isLoading } = useAuth0();
    const navigate = useNavigate();
    const location = useLocation();
    const hideFooter = location.pathname.startsWith('/cases_video/');
    const [isDrawing, setIsDrawing] = useState(false);
    const [drawMode, setDrawMode] = useState("");


    const handleLogoClick = () => navigate('/');

    const handleSignOut = () => {
        logout({
            logoutParams: {
                returnTo: process.env.REACT_APP_LOGOUT_URL,
                client_id: process.env.REACT_APP_AUTH0_CLIENT_ID // Ensure this is passed
            }
        });
    };

    useEffect(() => {
        const handleDrawModeUpdate = (event) => {
            setDrawMode(event.detail);
            setIsDrawing(event.detail !== ""); // True if drawMode is active
        };

        window.addEventListener("drawModeUpdate", handleDrawModeUpdate);
        return () => window.removeEventListener("drawModeUpdate", handleDrawModeUpdate);
    }, []);

    useEffect(() => {
        if (!location.pathname.startsWith("/cases_video/")) {
            setDrawMode(""); // Clear drawMode when leaving video page
            setIsDrawing(false);
        }
    }, [location.pathname]);

    const handleNavigation = (event) => {
        if (isDrawing && drawMode !== "") { 
            event.preventDefault();
            const confirmExit = window.confirm("You have unsaved changes. Leave?");
            if (confirmExit) {
                setDrawMode(""); // Reset after confirming navigation
                setIsDrawing(false);
                navigate(event.target.getAttribute("href"));
            }
        }
    };

    useEffect(() => {
        const links = document.querySelectorAll("a.nav-link"); // Only intercept navigation links
        links.forEach((link) => link.addEventListener("click", handleNavigation));

        return () => links.forEach((link) => link.removeEventListener("click", handleNavigation));
    }, [isDrawing, drawMode]);

    if (isLoading) return <Auth0Preloader />;

    return (
        <div className="App">
            <CookieConsent/>
            <header className="header">
                <div className="logo" onClick={handleLogoClick}>InOperis</div>
                <nav className="nav">
                    <Link to="/" className="nav-link">Home</Link>
                    {isAuthenticated ? (
                        <>
                            <Link to="/cases_home" className="nav-link">Cases</Link>
                            <Link to="/account" className="nav-link">Account</Link>
                            <button className="nav-link logout-button" onClick={handleSignOut}>Logout</button>
                        </>
                    ) : (
                        <button className="nav-lin login-button" onClick={() => loginWithRedirect()}>Login</button>
                    )}
                </nav>
            </header>

            <main className="main-content">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/cases_home" element={<AuthGuard><CasesHome /></AuthGuard>} />
                    <Route path="/cases_video/:videoId" element={<AuthGuard><CasesVideo /></AuthGuard>} />
                    <Route path="/account" element={<AuthGuard><Account /></AuthGuard>} />
                    <Route path="/upload_video" element={<AuthGuard><UploadVideo /></AuthGuard>} />
                </Routes>
            </main>

            {!hideFooter && (
                <footer className="footer">
                    <div>© 2024 InOperis</div>
                    <div className="contact-info">
                        <div className="contact-item">
                            <p>Contact Us</p>
                            <p>Inoperis@gmail.com</p>
                        </div>
                        <div className="contact-item">
                            <p>Montreal</p>
                            <p>Quebec, Canada</p>
                        </div>
                    </div>
                </footer>
            )}
        </div>
    );
};

// AuthGuard Component for Protected Routes
const AuthGuard = ({ children }) => {
    const { isAuthenticated, loginWithRedirect } = useAuth0();


    if (!isAuthenticated) {
        loginWithRedirect();
        return null;
    }

    return children;
};

export default App;