import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import VideoPlayer from '../videoplayer/videoplayer.js';
import './cases_video.css';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react'; // Import Auth0 hook

const API_URL = process.env.REACT_APP_API_URL;
const CLOUDFRONT_URL = process.env.REACT_APP_CLOUDFRONT_LINK;

function CasesVideo() {
  const { videoId } = useParams();
  const [videoMetadata, setVideoMetadata] = useState(null);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(null)

  // ✅ Instead of useAuthHeader, we use useAuth0
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    const getToken = async () => {
      try {
        const tok = await getAccessTokenSilently();
        setToken(tok);
      } catch (error) {
        console.error("Error fetching token:", error);
      }
    };
    getToken();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    // Don't attempt fetch if user isn't authenticated or is still loading
    if (!isAuthenticated || isLoading) return;

    const fetchVideoMetadata = async () => {
      if (!token) return;

      try {


        // 1. Fetch the video metadata
        const response = await axios.get(`${API_URL}/video/${videoId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          const metadata = response.data;

          // 2. If operatedBy is an array of user IDs, fetch each surgeon's name
          const surgeonIds = metadata.operatedBy || [];
          const surgeonNames = await Promise.all(
            surgeonIds.map(async (id) => {
              try {
                const surgeonResponse = await axios.get(`${API_URL}/user/name/${id}`, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                });
                return surgeonResponse.data.name;
              } catch (error) {
                console.error(`Failed to fetch name for surgeon ID: ${id}`, error);
                return 'Unknown Surgeon';
              }
            })
          );

          // 3. Combine the names into a single string
          metadata.operatedBy = surgeonNames.join(', ');
          setVideoMetadata(metadata);
        } else {
          console.error('Failed to fetch video metadata:', response.statusText);
          setError('Failed to fetch video metadata');
        }
      } catch (error) {
        console.error('Error fetching video metadata:', error);
        setError('Error fetching video metadata');
      }
    };

    fetchVideoMetadata();
  }, [videoId, token, isAuthenticated, isLoading]);

  // Handle loading/auth states
  if (isLoading) {
    return (
      <div className="loader_cv-container">
        <div className="loader_cv"></div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return <div>Please log in to view this content.</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!videoMetadata) {
    return (
      <div className="loader_cv-container">
        <div className="loader_cv"></div> {/* Centered loader in video section */}
      </div>
    );
  }

  return (
    <div className="cv-content">
      <div className="cv-video-section">
        <VideoPlayer
          videoLink={`${CLOUDFRONT_URL}/videos/${videoMetadata.videoId}/HLS/${videoMetadata.videoId}.m3u8`}
          videoMetadata={videoMetadata}
        />
      </div>
    </div>
  );
}

export default CasesVideo;